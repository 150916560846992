import classNames from "classnames";
import SquareImageLeftTitle from "src/components/articledisplays/SquareImageLeftTitle";
import { ArticleBlockSponsorProps } from "src/components/forsiden/ArticleBlockV2";
import TopOfPageSponsor from "src/components/takoversponsor/TopOfPageSponsor";
import theme from "src/components/themes/DefaultTheme";
import styled from 'src/lib/styles/css';
import { GroupedLayoutObject, IMostReadNews, IVerticalArticleList } from "src/lib/types/IForsidenV2";

//#region [Props]
type VerticalArticleListProps = {
    block: GroupedLayoutObject<IVerticalArticleList | IMostReadNews>;
    className?: string
} & ArticleBlockSponsorProps;
//#endregion

//#region [Component]
export default function VerticalArticleList({ block, className, sponsorClickUrl, sponsorTopImageDesktop, sponsorTopImageMobile }: VerticalArticleListProps) {
    const hasTopSponsor = !!sponsorTopImageDesktop;
    return <section className={className}>
        <SListHeader>
            <h2>{block.label}</h2>
            {hasTopSponsor && <STopOfPageSponsor clickUrl={sponsorClickUrl} desktopImages={sponsorTopImageDesktop} />}
        </SListHeader>
        <SList className={classNames(block.themes ?? "dark", block.showSeparators ? "withSeparators" : undefined)}>
            {block.articles?.map((doc) => <SquareImageLeftTitle key={doc._id} document={doc} themeValue={block.themes} showSeparator={block.showSeparators} />)}
        </SList>
    </section>;
}
//#endregion

//#region [Styles]
const SListHeader = styled.header`
    color: var(--textcolor);
    margin-top: 1.5em;
    margin-bottom: 1em;
    gap: 10px;
    align-items: flex-end !important;

    h2 {
        white-space: nowrap;
        flex: 0 0 auto;
        font-size: ${theme.fonts.tittelS.size};
        @media ${theme.mq.desktop} {
            font-size: ${theme.fonts.tittelM.size};
        }
    }

`;

const STopOfPageSponsor = styled(TopOfPageSponsor)`
    flex: 1 1 auto;
    .topOfPageSponsorInnerContainer {
        @media ${theme.mq.desktop} {
            margin-bottom: -1em;
        }
    }
`;

const SList = styled.div`
    border-radius: 8px;
    padding: 12px;

    background: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));

    &.light {
        background: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
    }

    &.lightGradient {
        background: ${theme.palette.gradient1};
    }

    &.gold {
        background: ${theme.palette.gullHovedfarge};
    }

    display: flex;
    flex-direction: column;

    @media ${theme.mq.desktop} {
        padding: 20px;
    }

    gap: 15px;
    @media ${theme.mq.desktop} {
        gap: 20px;
    }

    &.withSeparators {
        gap: 30px;
        @media ${theme.mq.desktop} {
            gap: 42px;
        }

    }



`;

//#endregion